import ApiService from "@/core/services/api.service";

// endpoints
export const API_GENERATE_RESET_PASSWORD_CODE =
  "/api/Users/GenerateResetPasswordCode";
export const API_RESET_PASSWORD = "/api/Users/ResetPassword";

// action types
export const FORGOT_PASSWORD = "forgotPassword";
export const RESET_PASSWORD = "resetPassword";

// mutation types

const state = {};

const getters = {};

const actions = {
  async [FORGOT_PASSWORD](context, email) {
    try {
      await ApiService.post(API_GENERATE_RESET_PASSWORD_CODE, {
        email
      });
      return { success: true };
    } catch ({ response }) {
      return { success: false, error: response.data };
    }
  },
  async [RESET_PASSWORD](context, { email, password, confirmPassword, code }) {
    try {
      await ApiService.post(API_RESET_PASSWORD, {
        email,
        password,
        confirmPassword,
        code
      });
      return { success: true };
    } catch ({ response }) {
      return { success: false, error: response.data };
    }
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};
