import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

/**
 * Service to call HTTP request via Axios
 */
export default {
  init() {
    Vue.use(VueAxios, axios);
    axios.defaults.baseURL = baseUrl;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(`${baseUrl}${resource}`, params).catch(error => {
      // console.log(error);
      throw new Error(`[Dashboard] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${baseUrl}${resource}/${slug}`).catch(error => {
      // console.log(error);
      throw new Error(`[Dashboard] ApiService ${error}`);
    });
  },
  /**
   * Send the GET HTTP request with paged query
   * @param resource
   * @param slug
   * @returns {*}
   */
  getQuery(resource, query = "") {
    return Vue.axios.get(
      `${baseUrl}${resource}?${query}`
    ); /*.catch(error => {
      // console.log(error);
      throw new Error(`[Dashboard] ApiService ${error}`);
    });*/
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${baseUrl}${resource}`, params);
    /*.catch(error => {
      console.log(error);
      throw new Error(`[Dashboard] ApiService ${error}`);
    });*/
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  postFormData(resource, params) {
    return Vue.axios.post(`${baseUrl}${resource}`, params, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${baseUrl}${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${baseUrl}${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(`${baseUrl}${resource}`).catch(error => {
      // console.log(error);
      throw new Error(`[Dashboard] ApiService ${error}`);
    });
  },

  base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
};
