import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/store";
import snackbarPlugin from "@/core/plugins/snackbar";
import ApiService from "@/core/services/api.service";
import { REFRESH } from "@/core/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/store/config.module";
import httpInterceptors from "@/core/helpers/httpInterceptors";
// import Mgr from "@/core/services/security.service";
import VueKonva from "vue-konva";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/tiptap-vuetify";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
//import { mdiRegex } from "@mdi/js";

// API service init
ApiService.init();
// let mgr = new Mgr();

router.beforeEach(async (to, from, next) => {
  const rootStore = await import("@/core/store");
  const isAuthenticated = rootStore.default.getters.isAuthenticated;

  // console.log("router.beforeEach isAuthenticated", isAuthenticated);

  // window.confirm("TO" + to.fullPath);
  if (to.matched.some(record => record?.meta?.requiresAuth)) {
    // const rootStore = await import("@/core/store");
    let needsRoleCheck = to?.meta?.role;

    // window.confirm("user " + user);
    // let signedIn = user != null;
    // if (user == null)
    if (!isAuthenticated) {
      // user is not signed in here - redirect to login
      next({
        name: "login",
        query: { redirect: to.fullPath }
      });
    } else {
      let user = rootStore.default.getters.userInfo;
      if (user == null || !user.role) {
        await store.dispatch(REFRESH);

        user = rootStore.default.getters.userInfo;
      }

      let userRole = user?.role;
      // console.log("router.beforeEach user and role", user, userRole);

      // User is signed in here - check role if required
      if (needsRoleCheck) {
        if (userRole && userRole.length > 0) {
          let isAuthorized = false;
          if (typeof needsRoleCheck === "string") {
            isAuthorized = needsRoleCheck == userRole;
          } else if (typeof needsRoleCheck === "object") {
            let userRoles = userRole.split(",");

            isAuthorized = userRoles.some(el => {
              return needsRoleCheck.indexOf(el) > -1;
            });
          }
          if (isAuthorized) {
            next();
          } else {
            const customerViewRoles = [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ];

            const employeeViewRoles = [
              "Regional Director",
              "Regional Manager",
              "Executive",
              "Executive Team",
              "Branch Manager",
              "Account Manager",
              "Crew Leader"
            ];

            const userRoles = userRole.split(",");

            if (
              userRoles.some(el => {
                return customerViewRoles.indexOf(el) > -1;
              })
            ) {
              next({ name: "CustomerViewDashboard" });
            } else if (
              userRoles.some(el => {
                return employeeViewRoles.indexOf(el) > -1;
              })
            ) {
              next({ name: "Customers" });
            } else {
              next("/404");
            }
          }
        } else {
          next({
            name: "login",
            query: { redirect: to.fullPath }
          });
        }
      } else {
        next();
      }
      // if (!signedIn) {
      //   next({
      //     name: "login",
      //     query: { redirect: to.fullPath }
      //   });
      // } else {
      //   next();
      // }
    }
  } else {
    next();
  }

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

httpInterceptors.register();

// Global snackbar notifier
Vue.use(snackbarPlugin, { store });
Vue.use(VueKonva);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
