import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/customers",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/performanceReport",
          name: "PerformanceReport",
          component: () => import("@/view/pages/customer/PerformanceReport.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers",
          name: "Customers",
          component: () => import("@/view/pages/customer/Customers.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/yn-team-directory",
          name: "YNTeamDirectory",
          component: () => import("@/view/pages/YNTeamDirectory.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/file-library",
          name: "FileLibraryFolders",
          component: () => import("@/view/pages/FileLibrary.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/file-library/:id",
          name: "FileLibraryFolder",
          props: true,
          component: () => import("@/view/pages/FileLibrary.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/service-requests",
          name: "ServiceRequests",
          component: () =>
            import("@/view/pages/serviceRequests/ServiceRequests.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/service-requests/:requestId/update",
          name: "UpdateServiceRequest",
          props: true,
          component: () =>
            import("@/view/pages/serviceRequests/ServiceRequestUpdate.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/message-center",
          name: "MessageCenter",
          component: () => import("@/view/pages/messages/MessageCenter.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/message-center/messages/new",
          name: "SendNewMessage",
          component: () => import("@/view/pages/messages/MessageNew.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/message-center/messages/:messageId/view",
          name: "ViewMessage",
          props: true,
          component: () => import("@/view/pages/messages/MessageView.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/content",
          name: "Content",
          component: () => import("@/view/pages/Content.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/settings",
          name: "Settings",
          component: () => import("@/view/pages/Settings.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/orange-reports/pending",
          name: "PendingOrangeReports",
          component: () => import("@/view/pages/OrangeReportsPending.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId",
          name: "CustomerDetails",
          props: true,
          component: () => import("@/view/pages/customer/CustomerDetails.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          name: "RedirectToCustomer",
          path: "/customers/redirectToCustomer/:customerId",
          props: true,
          component: () => import("@/view/pages/customer/CustomerRedirect.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          name: "CustomerSettings",
          path: "/customers/:customerId/settings",
          props: true,
          component: () => import("@/view/pages/customer/Settings.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/orange-reports/new",
          name: "NewOrangeReport",
          props: true,
          component: () => import("@/view/pages/customer/OrangeReportNew.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/create-orange-report",
          name: "CrewLeaderNewOrangeReport",
          props: false,
          component: () =>
            import("@/view/pages/customer/crewLeader/OrangeReportNew.vue"),
          meta: {
            requiresAuth: true,
            role: ["Crew Leader"]
          }
        },
        {
          path: "/customers/:customerId/orange-reports/:reportId/edit",
          name: "EditOrangeReport",
          props: true,
          component: () => import("@/view/pages/customer/OrangeReportEdit.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/orange-reports/:reportId/view",
          name: "ViewOrangeReport",
          props: true,
          component: () => import("@/view/pages/customer/OrangeReportView.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/orange-reports/pending",
          name: "CustomerPendingOrangeReports",
          props: true,
          component: () =>
            import("@/view/pages/customer/OrangeReportsPending.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/update-map",
          name: "ViewUpdateMap",
          props: true,
          component: () => import("@/view/pages/customer/UpdateMap.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/manage-portal-access",
          name: "ManagePortalAccess",
          props: true,
          component: () =>
            import("@/view/pages/customer/ManagePortalAccess.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/manage-portal-access/newUser",
          name: "ManagePortalAccessNewUser",
          props: true,
          component: () =>
            import("@/view/pages/customer/ManagePortalAccessNewUser.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/manage-portal-access/:userId/editUser",
          name: "ManagePortalAccessEditUser",
          props: true,
          component: () =>
            import("@/view/pages/customer/ManagePortalAccessEditUser.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/manage-portal-access/addYnMember",
          name: "ManagePortalAccessAddYnMember",
          props: true,
          component: () =>
            import("@/view/pages/customer/ManagePortalAccessAddYnMember.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        // Quarterly Plans
        {
          path: "/customers/:customerId/plans",
          name: "NinetyDayPlans",
          props: true,
          component: () => import("@/view/pages/customer/NinetyDayPlans.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/plans/:planId/view",
          name: "NinetyDayPlan",
          props: true,
          component: () => import("@/view/pages/customer/NinetyDayPlan.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/plans/new",
          name: "NinetyDayPlanNew",
          props: true,
          component: () => import("@/view/pages/customer/NinetyDayPlanNew.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/plans/:planId/edit",
          name: "NinetyDayPlanEdit",
          props: true,
          component: () =>
            import("@/view/pages/customer/NinetyDayPlanEdit.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        // Customer Quarterly Plan Templates
        {
          path: "/customers/:customerId/planTemplates",
          name: "CustomerQuarterlyPlanTemplates",
          props: true,
          component: () =>
            import("@/view/pages/customer/QuarterlyPlanTemplates.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/planTemplates/:planTemplateId/view",
          name: "CustomerQuarterlyPlanTemplate",
          props: true,
          component: () =>
            import("@/view/pages/customer/QuarterlyPlanTemplate.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/planTemplates/new",
          name: "CustomerQuarterlyPlanTemplateNew",
          props: true,
          component: () =>
            import("@/view/pages/customer/QuarterlyPlanTemplateNew.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/planTemplates/:templateId/edit",
          name: "CustomerQuarterlyPlanTemplateEdit",
          props: true,
          component: () =>
            import("@/view/pages/customer/QuarterlyPlanTemplateEdit.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },

        // Global Quarterly Plan Templates
        {
          path: "/planTemplates",
          name: "QuarterlyPlanTemplates",
          props: true,
          component: () =>
            import(
              "@/view/pages/quarterlyPlanTemplates/QuarterlyPlanTemplates.vue"
            ),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager"
            ]
          }
        },
        {
          path: "/planTemplates/new",
          name: "QuarterlyPlanTemplateNew",
          props: true,
          component: () =>
            import(
              "@/view/pages/quarterlyPlanTemplates/QuarterlyPlanTemplateNew.vue"
            ),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager"
            ]
          }
        },
        {
          path: "/planTemplates/:templateId/edit",
          name: "QuarterlyPlanTemplateEdit",
          props: true,
          component: () =>
            import(
              "@/view/pages/quarterlyPlanTemplates/QuarterlyPlanTemplateEdit.vue"
            ),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager"
            ]
          }
        },

        // Orange Reports
        {
          path: "/customers/:customerId/orange-reports",
          name: "OrangeReports",
          props: true,
          component: () => import("@/view/pages/customer/OrangeReports.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/invoices",
          name: "Invoices",
          props: true,
          component: () => import("@/view/pages/customer/Invoices.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/invoices/new",
          name: "InvoiceNew",
          props: true,
          component: () => import("@/view/pages/customer/InvoiceNew.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/proposals",
          name: "Proposals",
          props: true,
          component: () => import("@/view/pages/customer/Proposals.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/proposals/new",
          name: "ProposalNew",
          props: true,
          component: () => import("@/view/pages/customer/ProposalNew.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/documents",
          name: "Documents",
          props: true,
          component: () => import("@/view/pages/customer/Documents.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/documents/new",
          name: "DocumentNew",
          props: true,
          component: () => import("@/view/pages/customer/DocumentNew.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/messages",
          name: "CustomerMessages",
          props: true,
          component: () => import("@/view/pages/customer/Messages.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/messages/new",
          name: "CustomerMessageNew",
          props: true,
          component: () => import("@/view/pages/customer/MessageNew.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/messages/:messageId/view",
          name: "CustomerMessageView",
          props: true,
          component: () => import("@/view/pages/customer/MessageView.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/requests",
          name: "CustomerRequests",
          props: true,
          component: () => import("@/view/pages/customer/Requests.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/requests/new",
          name: "CustomerNewServiceRequest",
          props: true,
          component: () => import("@/view/pages/customer/RequestNew.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        },
        {
          path: "/customers/:customerId/requests/:requestId/update",
          name: "CustomerUpdateServiceRequest",
          props: true,
          component: () => import("@/view/pages/customer/RequestUpdate.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Executive",
              "System Admin",
              "Executive Team",
              "Regional Director",
              "Regional Manager",
              "Branch Manager",
              "Account Manager",
              "Crew Leader",
              "Business Development"
            ]
          }
        }
        // {
        //   path: "/edit-user/:userId",
        //   name: "EditUser",
        //   component: () => import("@/view/pages/EditUser.vue")
        // },
        // {
        //   path: "/user-events/:userId",
        //   name: "UserEvents",
        //   component: () => import("@/view/pages/UserEvents.vue")
        // },
        // {
        //   path: "/user-groups/:userId",
        //   name: "UserGroups",
        //   component: () => import("@/view/pages/UserGroups.vue")
        // }
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth.vue"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login.vue")
        },
        {
          name: "forgotPassword",
          path: "/forgotPassword",
          component: () => import("@/view/pages/auth/ForgotPassword.vue")
        },
        {
          name: "resetPassword",
          path: "/resetPassword",
          component: () => import("@/view/pages/auth/ResetPassword.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/ResidentRegistration/Layout.vue"),
      children: [
        {
          name: "ResidentRegistration",
          path: "/resident-user-validation",
          component: () => import("@/view/pages/ResidentRegistration/Register.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/layout/CustomerViewLayout"),
      children: [
        {
          name: "CustomerViewDashboard",
          path: "/customerView/dashboard",
          component: () =>
            import("@/view/pages/customerView/CustomerViewDashboard.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          name: "CustomerViewSettings",
          path: "/customerView/:customerId/settings",
          props: true,
          component: () => import("@/view/pages/customerView/Settings.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          name: "CustomerView",
          path: "/customerView/:customerId",
          props: true,
          component: () => import("@/view/pages/customerView/CustomerView.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/manage-portal-access",
          name: "CustomerViewManagePortalAccess",
          props: true,
          component: () =>
            import("@/view/pages/customerView/ManagePortalAccess.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/manage-portal-access/newUser",
          name: "CustomerViewManagePortalAccessNewUser",
          props: true,
          component: () =>
            import("@/view/pages/customerView/ManagePortalAccessNewUser.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/manage-portal-access/:userId/editUser",
          name: "CustomerViewManagePortalAccessEditUser",
          props: true,
          component: () =>
            import("@/view/pages/customer/ManagePortalAccessEditUser.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/plans",
          name: "CustomerViewNinetyDayPlans",
          props: true,
          component: () =>
            import("@/view/pages/customerView/NinetyDayPlans.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/plans/:planId/view",
          name: "CustomerViewNinetyDayPlan",
          props: true,
          component: () =>
            import("@/view/pages/customerView/NinetyDayPlan.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/orange-reports",
          name: "CustomerViewOrangeReports",
          props: true,
          component: () =>
            import("@/view/pages/customerView/OrangeReports.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/orange-reports/:reportId/view",
          name: "CustomerViewOrangeReportView",
          props: true,
          component: () =>
            import("@/view/pages/customerView/OrangeReportView.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/invoices",
          name: "CustomerViewInvoices",
          props: true,
          component: () => import("@/view/pages/customerView/Invoices.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/documents",
          name: "CustomerViewDocuments",
          props: true,
          component: () => import("@/view/pages/customerView/Documents.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/documents/new",
          name: "CustomerViewDocumentNew",
          props: true,
          component: () => import("@/view/pages/customerView/DocumentNew.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/proposals",
          name: "CustomerViewProposals",
          props: true,
          component: () => import("@/view/pages/customerView/Proposals.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/messages",
          name: "CustomerViewMessages",
          props: true,
          component: () => import("@/view/pages/customerView/Messages.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/messages/new",
          name: "CustomerViewMessageNew",
          props: true,
          component: () => import("@/view/pages/customerView/MessageNew.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/messages/:messageId/view",
          name: "CustomerViewMessageView",
          props: true,
          component: () => import("@/view/pages/customerView/MessageView.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/sendKudos",
          name: "CustomerViewSendKudos",
          props: true,
          component: () => import("@/view/pages/customerView/SendKudos.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/requests",
          name: "CustomerViewRequests",
          props: true,
          component: () => import("@/view/pages/customerView/Requests.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/requests/new",
          name: "CustomerViewNewServiceRequest",
          props: true,
          component: () => import("@/view/pages/customerView/RequestNew.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        },
        {
          path: "/customerView/:customerId/requests/:requestId/update",
          name: "CustomerViewUpdateServiceRequest",
          props: true,
          component: () =>
            import("@/view/pages/customerView/RequestUpdate.vue"),
          meta: {
            requiresAuth: true,
            role: [
              "Property Manager",
              "Board Member",
              "Resident",
              "Tenant/Resident",
              "Tenant/Resident - Neighborhood",
              "Landscape Committee"
            ]
          }
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
