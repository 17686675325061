import axios from "axios";
import router from "@/router";
// import store from "@/core/store";
// import Mgr from "@/core/services/security.service";
import JwtService from "@/core/services/jwt.service";

export default {
  register() {
    axios.interceptors.request.use(
      request =>
        new Promise(resolve => {
          // let mgr = new Mgr();
          // let token = "";
          // mgr.getAcessToken().then(acessToken => {
          //   request.headers.common.Authorization = `Bearer ${acessToken}`;
          //   resolve(request);
          // });
          // console.log(token);
          // request.headers.common.Authorization = `Bearer ${store.state.auth.token}`;
          request.headers.common.Authorization = `Bearer ${JwtService.getToken()}`;
          resolve(request);
        })
    );
    axios.interceptors.response.use(
      response => response,
      ex => {
        // console.log("axios.interceptors.response ex", ex);
        if (ex.response.status === 401) {
          router.push({ path: "/login" });
          return Promise.reject(ex);
        }
        return Promise.reject(ex);
      }
    );
  }
};
