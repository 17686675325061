// import jwtDecode from 'jwt-decode'
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
// import { OidcClient } from "oidc-client";
// import "babel-polyfill";
// endpoints
export const API_TOKEN = "/api/token";

export const API_TOKEN_REFRESH = "/api/token/refresh";

// action types
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REFRESH = "refresh";
export const REGISTER = "register";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

// ApiService.init();

const state = {
  errors: null,
  user: {},
  token: JwtService.getToken(),
  isAuthenticated: !!JwtService.getToken(),
  userInfo: {}
};

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  userInfo(state) {
    return state.userInfo;
  },
  roles(state) {
    if (!state.token) {
      return "";
    }
    return state.userInfo.role;
    // eslint-disable-next-line no-unused-vars
    // const roleClaim =
    //   'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    // const decodedToken = jwtDecode(state.token)
    // const roles = decodedToken[roleClaim]
    // if (!roles) {
    //   return []
    // }

    // if (typeof roles === 'string') {
    //   const result = []
    //   result.push(roles)
    //   return result
    // }

    // return decodedToken[roleClaim] ?? []
  }
};

const actions = {
  async [LOGIN](context, { username, password }) {
    try {
      const { data } = await ApiService.post(API_TOKEN, {
        username,
        password
      });

      context.commit(SET_AUTH, data);
    } catch ({ response }) {
      if (response?.status >= 400) {
        context.commit(
          SET_ERROR,
          response?.data ?? "Login or password is not valid"
        );
      } else if (response?.data) {
        context.commit(SET_ERROR, [response.data.error_description]);
      }
    }
  },
  async [REFRESH](context) {
    if (JwtService.getToken()) {
      try {
        const { data } = await ApiService.post(API_TOKEN_REFRESH);
        context.commit(SET_AUTH, data);
        return data;
      } catch ({ response }) {
        if (response?.status >= 400) {
          context.commit(SET_ERROR, "Login or password is not valid");
          return response;
        } else if (response?.data) {
          context.commit(SET_ERROR, [response.data.error_description]);
          return response.data;
        }
      }
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      context.commit(PURGE_AUTH);
      resolve();
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    // console.log("SET_AUTH state ", state)
    // console.log("SET_AUTH user ", user)
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    state.token = state.user.token;
    JwtService.saveToken(state.token);
    state.userInfo = user.user;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    state.userInfo = {};
    state.token = null;
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
