import ApiService from "@/core/services/api.service";
import qs from "querystring";

// action types
export const GET_QUERY = "getQuery";
export const GET_PAGED = "getPagedQuery";
export const POST_DATA = "postData";
export const POST_DATA_2 = "postData2";
export const PUT_DATA = "putData";
export const DELETE = "delete";

// mutation types
export const SET_QUERY_RESULT = "setQueryResult";
export const SET_ERROR = "setCrudError";
export const SET_INVALID_RESPONSE = "setCrudInvalid";
export const SET_DATA = "setData";

//api links
export const API_USERS = "/api/Users";
export const API_CUSTOMERS = "/api/Customers";
export const API_ORANGE_REPORT_SERVICES = "/api/OrangeReportServices";
export const API_ORANGE_REPORTS = "/api/OrangeReports";
export const API_EMPLOYEES = "/api/Employees";
export const API_CONTACTS = "/api/Contacts";
export const API_WEATHER = "/api/Weather";
export const API_PHOTO_UPLOAD = "/api/Files/uploadPhoto";
export const API_FILE_UPLOAD = "/api/Files/uploadFile";
export const API_SERVICE_REQUESTS = "/api/ServiceRequests";
export const API_SERVICE_REQUEST_TYPES = "/api/ServiceRequestTypes";
export const API_SERVICE_REQUEST_PRIORITIES = "/api/ServiceRequestPriorities";
export const API_MESSAGES = "/api/Messages";
export const API_KUDOS = "/api/Kudos";
export const API_CURRENT_CONTACT_INFO = "/api/Contacts/current";
export const API_CURRENT_USER_INFO = "/api/Users";
export const API_USER_INFO = "/api/Users";
export const API_NOTIFICATIONS = "/api/Notifications";
export const API_FOLDERS = "/api/folders";
export const API_QUARTERLY_PLAN_TEMPLATES = "/api/quarterlyPlanTemplates";
export const API_REPORTS = "/api/reports";
export const API_USER_EXISTS = "/api/Users/CheckUserExists";

// export const API_NINETY_DAY_PLAN = "/api/Weather";

const state = {
  errors: null
};

const getters = {
  hasResponseErrors(state) {
    return state.errors !== null;
  },
  responseErrors(state) {
    const objToProcess = state.errors;
    var text = "";
    if (!objToProcess && objToProcess == null) return text;
    if (typeof objToProcess === "string" || objToProcess instanceof String)
      return objToProcess;
    Object.keys(objToProcess).forEach(function(k) {
      if (objToProcess[k].length && objToProcess[k].length > 0)
        text += `${objToProcess[k][0]} \n`;
    });
    return text;
  }
};

const actions = {
  [GET_PAGED](context, payload) {
    return new Promise(resolve => {
      const url = payload.listName;
      const query = qs.stringify(payload.params);

      ApiService.getQuery(url, query)
        .then(response => {
          context.commit(SET_QUERY_RESULT, {
            statePoint: payload.statePoint,
            data: response.data
          });
          resolve(response);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            context.commit(
              SET_ERROR,
              response.data.errors ? response.data.errors : response.data
            );
          } else {
            context.commit(SET_ERROR, `${GET_QUERY} ${payload.listName} error`);
          }
          resolve(response);
        });
    });
  },
  [GET_QUERY](context, payload) {
    return new Promise(resolve => {
      var url = payload.listName;
      let slug = "";
      if (payload.id) url = `${url.trimEnd("/")}/${payload.id}`;
      if (payload.slug) {
        slug = payload.slug;
      }

      url = url.trimEnd("/");

      ApiService.get(url, slug)
        .then(response => {
          context.commit(SET_QUERY_RESULT, {
            statePoint: payload.statePoint,
            data: response.data
          });
          resolve(response);
        })
        .catch(response => {
          if (response && response.data) {
            context.commit(
              SET_ERROR,
              response.data.errors ? response.data.errors : response.data
            );
          } else {
            context.commit(SET_ERROR, `${GET_QUERY} ${payload.listName} error`);
          }
          resolve(response);
        });
    });
  },
  async [POST_DATA_2](context, payload) {
    let url =
      payload.id && payload.id > 0
        ? `${payload.listName}/${payload.id}`
        : payload.listName;
    if (payload.postfix) url += `/${payload.postfix}`;

    try {
      const { data } = await ApiService.post(url, payload.data);

      // console.log("Post data 2 - response data", data);

      return { success: true, data };

      // context.commit(SET_DATA, {
      //   statePoint: payload.statePoint,
      //   data: data
      // });
    } catch ({ response }) {
      // console.log("Post data 2 - error response", response);
      // console.log("Post data 2 - error response data", response?.data);
      return {
        success: false,
        data: response?.data,
        validationErrors: response?.validationErrors
      };
      // if (response?.status >= 400) {
      //   context.commit(
      //     SET_ERROR,
      //     response?.data ?? `${POST_DATA_2} ${payload.listName} error`
      //   );
      // } else if (response?.data) {
      //   context.commit(SET_ERROR, [response.data.error_description]);
      // }
    }
  },
  [POST_DATA](context, payload) {
    return new Promise(resolve => {
      let url =
        payload.id && payload.id > 0
          ? `${payload.listName}/${payload.id}`
          : payload.listName;
      if (payload.postfix) url += `/${payload.postfix}`;

      ApiService.post(url, payload.data)
        .then(response => {
          if (response.data) {
            context.commit(SET_DATA, {
              statePoint: payload.statePoint,
              data: response.data
            });
          }
          resolve(response);
        })
        .catch(response => {
          // console.log("Post data error", response);
          if (response && response.data) {
            context.commit(SET_INVALID_RESPONSE, response.data);
            resolve(response);
          } else {
            context.commit(SET_ERROR, `${POST_DATA} ${payload.listName} error`);
            resolve();
          }
        });
    });
  },
  [PUT_DATA](context, payload) {
    return new Promise(resolve => {
      let url =
        payload.id && payload.id > 0
          ? `${payload.listName}/${payload.id}`
          : payload.listName;
      if (payload.postfix) url += `/${payload.postfix}`;
      ApiService.put(url, payload.data)
        .then(response => {
          if (response.data) {
            context.commit(SET_DATA, {
              statePoint: payload.statePoint,
              data: response.data
            });
          }
          resolve(response);
        })
        .catch(response => {
          if (response && response.data) {
            context.commit(SET_INVALID_RESPONSE, response.data);
            resolve(response.data);
          } else {
            context.commit(SET_ERROR, `${POST_DATA} ${payload.listName} error`);
            resolve();
          }
        });
    });
  },
  [DELETE](context, payload) {
    return new Promise(resolve => {
      const url = `${payload.listName.trimEnd("/")}/${payload.id}`;
      ApiService.delete(url)
        .then(response => {
          resolve(response);
        })
        .catch(response => {
          if (response && response.data) {
            context.commit(SET_ERROR, response.data.errors);
          } else {
            context.commit(SET_ERROR, `${DELETE} ${payload.listName} error`);
          }
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_INVALID_RESPONSE](state, error) {
    state.errors = error;
  },
  [SET_QUERY_RESULT](state, payload) {
    state.errors = {};
    state.invalidResponse = null;
    if (payload.statePoint && payload.statePoint.length)
      state[payload.statePoint] = payload.data.data
        ? payload.data.data
        : payload.data;
  },
  [SET_DATA](state, payload) {
    // console.log("set data payload", payload);
    state.errors = {};
    state.invalidResponse = null;
    if (payload.statePoint && payload.statePoint.length)
      state[payload.statePoint] = payload.data.data
        ? payload.data.data
        : payload.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
